.App {

}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.text-blue {
  color:"#4AA1D9";
}

.loading, .loading-expanded-img {
  position:fixed;
  top:0;
  right:0;
  left:0;
  bottom:0;
  background-color:#7fb8dc;
  z-index:999;
}

.loading-expanded-img {
  background-color:rgba(74,161,217,.75);
  z-index:9999;
}

.sending-email {
  background-color:#7fb8dcde;
}

.sending-email .overlay-close-btn, .loading-expanded-img .overlay-close-btn {
  position:fixed;
  top:1rem;
  right:1rem;
  color:#fff;
  font-size-:80%;
  cursor: pointer;
  font-weight-: bold;
}

.estimator-section {
  margin-bottom:2rem;
}

.estimator-section h2 {
  font-weight:300;
}

.estimator-summary-container{
  height: 100%;
  background: #FAFAFA;
  padding:1rem;
  border: 1px solid #D0D0D0;
  min-width:275px;
  margin: 0 0 0 1rem;
  max-height:80vh;
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

.estimator-summary-total {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.estimator-detail .label, .estimator-detail .value{
  font-size: 90%;
  line-height:2rem;
}

.estimator-detail .value{
  font-weight: bold;
}

.canvas-edit-btn {
  width:95px;
  color:#4AA1D9;
  padding:.25rem;
  border: .09rem solid #4AA1D9;
  border-radius:0;
  margin-right:.5rem;
  background:#fff;
}

.canvas-edit-btn span {
  font-size:12px;
}

.shape-btn {
  border-width:2px !important;
}
.shape-btn.selected {
  border-color:#4AA1D9 !important;
}

.shape-tabs .selected {
  color:#fff;
  background: #4AA1D9;
  border-color: #4AA1D9 !important;
}

.shape-tabs .add {
  color:#4AA1D9;
}

.smaller {
  font-size: 50%;
  font-weight: 500;
}

.shape-tab {
  cursor:pointer;
}

.border-bottom-section {
  border-bottom:2px solid #4AA1D9;
}

hr.section-seperator {
  border-top:4px solid #4AA1D9 !important;
}

hr.section-seperator.secondary {
  border-top:2px solid #c7c7c7 !important;
}


.option-container {
  display: flex;
  flex-direction: column;
  width:25%;
}

.option-container.nowidth{
  width:unset;
}

.option {
  display: flex;
  flex-direction: column;
  border: 2px solid #eee;
  border-top-width: 15px;
  margin:0 1rem 1rem 0;
  padding:1rem 1rem 1.5rem;
  cursor: pointer;
  flex-grow: 1;
}

.option.thinner{
  padding-bottom:.75rem;
}

.option .info{
  font-size:90%;
  color:#404040;
}

.selected .option {
  border-color: #009bff;
}

.products .option img {
  min-height:150px;
  min-width:150px;
  margin-bottom: .1rem;
  background:#eee;
}

.swatch-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 164px);
  grid-template-rows: auto;
  box-sizing: border-box;
  overflow: hidden;
  place-content: start space-between;
}

.swatch{
  height: 270px;
  width:148px;
  display:flex;
  flex-direction: column;
  border: 3px solid #eee;
  margin-bottom:1rem;
}

.swatch.selected {
  border-color: #009bff;
}

.swatch img {
  min-width: 100%;
  height: auto;
  min-height:142px;
}

.swatch .product-details {
  display: flex;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.swatch .product-details .product-name {
  color: rgb(51, 51, 51);
  text-align: left;
  -webkit-box-flex: 1 !important;
  flex-grow: 1 !important;
  padding: 5px 10px 0px;
  margin: 0px;
  font-size:90%;
}

.swatch .product-details .product-price {
  display: flex;
  flex-direction: row;
  padding: 0px 10px 10px;
}

.swatch .product-details .product-price .unit-cost {
  font-weight:bold;
  font-size: 115%;
}
.swatch .product-details .product-price .unit-cost-mark {
  font-weight:bold;
  font-size:95%;
  vertical-align: sub;
  vertical-align: -webkit-baseline-middle;
}

.swatch .product-details .instock, .swatch .product-details .outofstock  {
  padding: 0px 10px 0px;
  font-size: 90%;
}

.swatch .product-details .instock {
    font-weight:bold;
    line-height:20px;
}

.swatch .product-details .outofstock {
  color:red;
  font-style: italic;
}

.summary-stone-image {
  background-size: cover !important;
  height: 100px;
}

.estimator-section.plumbing .option .name,
.estimator-section.removal .option .name,
{
  padding-bottom:1rem;
}

.estimator-section.sink-types .option .name {
  font-weight:700;
}

.estimator-section.sink-type-options .info {
  margin-bottom:.5rem;
}

.estimator-section.sink-type-options .name  {
  font-weight:500;
}

.estimator-section.sink-type-options .property {
  font-size:75%;
  font-weight:500;
}

.estimator-section.sink-type-options .property span {
  padding-right:5px;
}

.defaul-cursor{
  cursor: default;
}

.pointer-cursor{
  cursor:pointer;
}

/*
---------------------
https://codepen.io/TommiTikall/pen/KymYBN
*/



@-webkit-keyframes checkmarkAnimation {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes checkmarkAnimation {
  100% {
    stroke-dashoffset: 0;
  }
}


.wrapper {
  font-size: 18px;
  margin: 0 auto;
  max-width: 800px;
  padding: 0 20px;
}

.input-wrapper {
  margin: 20px 0;
  position: relative;
}

.fancy-check-label,
.fancy-radio-label {
  display: inline-block;
  line-height: 24px;
  padding-left: 36px;
  position: relative;
  -webkit-user-select: no-select;
     -moz-user-select: no-select;
      -ms-user-select: no-select;
          user-select: no-select;
}

.crappy-browser .fancy-check-label .checkmark-path {
  opacity: 0;
}
.fancy-check-label {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.fancy-check:checked + .fancy-check-label {
  -color: #4AA1D9;
}
.fancy-check:checked + .fancy-check-label .fancy-checkbox {
  border-color: #4AA1D9;
}
.fancy-check:checked + .fancy-check-label .checkmark-path {
  -webkit-animation: checkmarkAnimation 0.5s 0s forwards;
          animation: checkmarkAnimation 0.5s 0s forwards;
}
.crappy-browser .fancy-check:checked + .fancy-check-label .checkmark-path {
  opacity: 1;
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
}

.fancy-label--text {
  position: relative;
}

.fancy-radio:checked + .fancy-radio-label {
  color: #4AA1D9;
}
.fancy-radio:checked + .fancy-radio-label .fancy-label--text::before {
  -webkit-transition: -webkit-transform 0.15s;
  transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.fancy-radio:checked + .fancy-radio-label .fancy-radiobutton {
  border-color: #4AA1D9;
  -webkit-transition: border-color 0.5s;
  transition: border-color 0.5s;
}
.fancy-radio:checked + .fancy-radio-label .radiobutton-dot {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  -webkit-transition: opacity 0.15s, -webkit-transform 0.15s;
  transition: opacity 0.15s, -webkit-transform 0.15s;
  transition: opacity 0.15s, transform 0.15s;
  transition: opacity 0.15s, transform 0.15s, -webkit-transform 0.15s;
}

.fancy-checkbox, .fancy-radiobutton {
  border: solid 2px #888;
  display: block;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
}
.fancy-checkbox,
.fancy-radiobutton {
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.fancy-checkbox {
  border-radius: 0;
}

.fancy-radiobutton {
  border-radius: 50%;
}

.radiobutton-dot {
  background-color: currentColor;
  border-radius: 50%;
  height: 12px;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
  width: 12px;
}

.checkmark {
  height: 20px;
  opacity: 1;
  width: 100%;
}
.checkmark .checkmark-path {
  stroke: #4AA1D9;
  stroke-width: 3px;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  stroke-miterlimit: 10;
  opacity: 1;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
}





/*
--------------------
*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .25s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


@media (max-width:1025px){
  .estimator-summary-container{
    position: fixed !important;
    right: 5px ;
    bottom: 0 !important;
    top: calc(100% - 295px) !important;
    height: 365px;
    z-index: 1030 !important;
    transition: all 0.5s ease;
    box-shadow: -1px 2px 5px 5px rgb(181, 181, 181) !important;
    width: 40% !important;
  }
  .estimator-summary-container.show{
    top:1% !important;
    height:97% !important;
    max-height: 100%;
    transition: all 0.5s ease;
    margin-bottom:1rem
  }
  .estimator-summary-total, .estimator-summary-container h5, .estimator-summary-container button {
    text-align: center;
  }
  .estimator-summary-container .h2 {
    font-size:50%;
  }
  .estimator-summary-container .label, .estimator-summary-container .value {
  }
  .estimator-summary-container .h6 {
    -font-size:100%;
  }
  .App {
    padding-bottom: 350px !important;
  }
  .shape-sides {
    width:25%;
  }
  .backdrop {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:100%;
    height: 100%;
    background:rgba(0,0,0,.25);
    z-index:1029;
  }
  .option-container {
    width:50%;
  }
}

@media (max-width:767px){
  .estimator-summary-container{
    position: fixed !important;
    right: 0 !important;
    bottom: 0 !important;
    top: calc(100% - 290px) !important;
    height: 365px;
    z-index: 1030 !important;
    width: 60% !important;
    transition: all 0.5s ease;
    box-shadow: -1px 2px 5px 5px rgb(181, 181, 181) !important;
    font-size:100%;
  }
}

@media (max-width:736px){
  .estimator-summary-container{
    top: calc(100% - 290px);
  }
}

@media (max-width:569px){
  .estimator-summary-container{
    position: fixed !important;
    right: 0 !important;
    bottom: 0 !important;
    top: calc(100% - 330px) !important;
    height: 365px;
    z-index: 1030 !imporatn;
    transition: all 0.5s ease;
    box-shadow: -1px 2px 5px 5px rgb(181, 181, 181) !important;
    font-size:150%;
  }
  .estimator-summary-container .label, .estimator-summary-container .value {
    line-height:200%;
    font-size:100%;
  }
  .estimator-summary-container .h2 {
    font-size:100%;
  }
  .estimator-summary-total, .estimator-summary-container h5, .estimator-summary-container button {
    font-size:100%;
    text-align: center;
  }
}

input.side-error{
  border-bottom-width: 3px;
}

.shape-error{
  background:#ffe6e8;
}

.sink-type-options {
  background:#d4d8de;
}

.btn-send-estimate{
  background: #48a3d9;
  color:white;
}

.btn-send-estimate:hover{
  background: #136796;
  color:#fff;
}

.btn-send-purchase{
  background: #28a745;
  color:white;
}

.btn-send-purchase:hover{
  background: #1c7931;
  color:#fff;
}
